import React from 'react'
import { Link } from 'gatsby'

// Styles 
import { SuccessHolder } from "./Success.styles"

const Success = () => (
    <SuccessHolder>
        <h4>Tack</h4>
        <p>Nu är din offertförfrågan är skickad till samtliga städfirmor.</p>
        <p>Städfirmor från Göteborg kommer ta kontakt med dig med var sin offert som du kan granska i lugn och ro.<br/>Vi önskar dig lycka till med din flytt.</p>
        <Link to={'/'}>Tillbaka till Startsidan</Link>

    </SuccessHolder>
)
export default Success